import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";

import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import HTable, { IHeadTabel } from "../../Common/HTable";

import StudentAction from "../../../Libs/Redux/Actions/Student.action";
import { IReport } from "../../../Libs/Models/IReport.model";
import { stableSort, OrderType } from '../../../Libs/Extensions/Sort.extension';
import { useState } from 'react';

const headCells: IHeadTabel[] = [
  { id: "muId", label: "รหัส", align: "center", sort: true, width: 80 },
  {
    id: "username",
    label: "ชื่อ-นามสกุล",
    align: "center",
    sort: true,
    width: 300,
  },
  {
    id: "scorePre",
    label: "PRE TEST",
    align: "center",
    sort: true,
    width: 145,
  },
  {
    id: "scoreSit1",
    label: "SITUATION#1",
    align: "center",
    sort: true,
    width: 100,
  },
  {
    id: "scoreSit2",
    label: "SITUATION#2",
    align: "center",
    sort: true,
    width: 100,
  },
  {
    id: "scoreSit3",
    label: "SITUATION#3",
    align: "center",
    sort: true,
    width: 100,
  },
  {
    id: "scorePost",
    label: "POST TEST",
    align: "center",
    sort: true,
    width: 145,
  },
  { id: "report", label: "REPORT", align: "center", sort: false, width: 100 },
  { id: "end", label: "", align: "center", sort: false, minWidth: 10 },
];

interface IProps {
  data: IReport[];
}

export function TableList(props: IProps) {
  const [orderBy, setOrderBy] = useState<keyof IReport>('muId')
  const [orderType, setOrderType] = useState(OrderType.Asc);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { situation } = useParams();

  //--------------------------------------------------//
  //--------------------------------------------------//
  const onClick = (value: IReport) => {
    dispatch(StudentAction.getStudentReports(value)); //--> .info
    if (situation) {
      navigate(`/${situation}/` + value.id);
    } else {
      navigate(`/situation1/` + value.id);
    }
  };

  const sortHandler = (id: keyof IReport) => {
    if (orderType === OrderType.Asc) {
      setOrderType(OrderType.Desc);
    } else {
      setOrderType(OrderType.Asc);
    }
    setOrderBy(id);
  }

  //--------------------------------------------------//
  //--------------------------------------------------//
  return (
    <TableContainer component={Paper} elevation={0}>
      <Table size="small" sx={{ width: '100%' }} aria-label="customized table">
        <HTable cells={headCells} sortHandler={sortHandler} />
        <TableBody>
          {stableSort(props.data, orderType, orderBy).map((row: IReport, index: number) => (
            <TableRow key={row.id}>
              <TableCell align="right">{row.muId}</TableCell>
              <TableCell align="center">{row.username}</TableCell>
              <TableCell align="center">
                {`${row.scorePre} (${row.nPre})`}
              </TableCell>
              <TableCell align="center">
                {`${row.scoreSit1} (${row.nSit1})`}
              </TableCell>
              <TableCell align="center">
                {`${row.scoreSit2} (${row.nSit2})`}
              </TableCell>
              <TableCell align="center">
                {`${row.scoreSit3} (${row.nSit3})`}
              </TableCell>
              <TableCell align="center">
                {`${row.scorePost} (${row.nPost})`}
              </TableCell>
              <TableCell align="center">
                <Button
                  variant="contained"
                  onClick={() => { onClick(row); }}
                >
                  <SystemUpdateAltIcon fontSize="small" />
                </Button>
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
