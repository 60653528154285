import {
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material'
import React from 'react'

export interface IHeadTabel {
  //id: keyof IStudent
  id: string
  label: string
  align?: 'center' | 'inherit' | 'justify' | 'left' | 'right'
  sort?: boolean
  minWidth?: number
  width?: number
  active?: boolean
  hidden?: boolean
}

interface IHeaderCell {
  cells: IHeadTabel[]
  //sortHandler?: React.MouseEventHandler<HTMLButtonElement>
  sortHandler?: any;
}

export default function HTable(props: IHeaderCell) {
  //const [order, setOrder] = useState<OrderType>(OrderType.Asc)
  //const [orderBy, setOrderBy] = useState<keyof IStudent>('sid')

  //--------------------------------------------------//
  //--------------------------------------------------//
  return (
    <TableHead>
      <TableRow>
        {props.cells.map((cell) => (
          <TableCell
            key={cell.id}
            align={cell.align}
            style={{
              minWidth: cell.minWidth ? cell.minWidth : 'auto',
              width: cell.width ? cell.width : 'auto',
            }}
          //sortDirection={orderBy === cell.id ? order : false}
          >
            {cell.sort ? (
              <TableSortLabel
                //active={orderBy === headCell.id}
                hideSortIcon={cell.hidden !== null ? cell.hidden : true}
                //direction={orderBy === headCell.id ? order : 'asc'}
                //onClick={sortHandler(headCell.id)}
                onClick={() => props.sortHandler && props.sortHandler(cell.id)}
              >
                <Typography variant="h5" sx={{ lineHeight: 0.9 }}>
                  <b>{cell.label}</b>
                </Typography>
              </TableSortLabel>
            ) : (
              <Typography variant="h5" sx={{ lineHeight: 0.9 }}>
                <b>{cell.label}</b>
              </Typography>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}
