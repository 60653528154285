import { AppBar, DialogActions, IconButton, Paper, Toolbar, TableContainer, Table, TableBody, TableRow, TableCell, Typography, CircularProgress, Snackbar, Alert } from '@mui/material';
import React, { useState, useEffect } from 'react'

import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import HTable from './HTable';
import { IHeadTabel } from './HTable';
import { IProfile, DProfile } from '../../Libs/Models/IProfile.model';
import UserProfilesService from '../../Libs/Services/UserProfiles.service';
import { green } from '@mui/material/colors';


interface IProps {
  onCloseHandler: () => void
}

const headCells: IHeadTabel[] = [
  {
    id: 'muId',
    label: 'รหัสนักศึกษา',
    width: 120,
    align: "right"
  },
  {
    id: 'username',
    label: 'ชื่อ-นามสกุล',
    minWidth: 180,
    align: "center"
  },
  {
    id: 'email',
    label: 'E-MAIL',
    minWidth: 180,
    align: "center"
  },
  {
    id: 'year',
    label: 'ปีการศึกษา',
    minWidth: 100,
    align: "center"
  },
  {
    id: 'roles',
    label: 'ROLE',
    minWidth: 100,
    align: "center"
  },
  {
    id: 'edit',
    label: '',
    width: 150,
  },
];

export function CreateStudent(props: IProps) {
  const fileReader = new FileReader();
  const [file, setFile] = useState();
  const [data, setData] = useState<IProfile[]>([]);

  const [isUploading, setUploading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);

  useEffect(() => {
    if (file) {
      fileReader.onload = function (event: any) {
        csvFileToArray(event.target.result);
      };
      fileReader.readAsText(file);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file])

  const buttonSx = {
    ...(success && {
      bgcolor: green[500],
      '&:hover': {
        bgcolor: green[700],
      },
    }),
  };

  //--------------------------------------------------//
  //--------------------------------------------------//
  const onChangeHandler = (e: any) => {
    e.preventDefault();
    setFile(e.target.files[0]);
  };

  const csvFileToArray = (str: String) => {
    const csvHeader = str.slice(0, str.indexOf("\n")).split(",");
    const csvRows = str.slice(str.indexOf("\n") + 1).split("\n");

    var arr: string[] = csvRows.map(i => {
      const values = i.split(",");
      const obj = csvHeader.reduce((object: any, header: string, index: number) => {
        object[header.trim()] = values[index];
        return object;
      }, {});
      return obj;
    });
    //arr = arr.filter((a: any) => a.email && a.email !== undefined)
    var user = arr.map((a: any) => {
      if ((a.email && a.email !== undefined && a.id && String(a.email).trim().length > 0) &&
        (a.id !== undefined && String(a.id).trim().length > 0)) {
        var __d__: IProfile = DProfile();
        __d__.muId = a.id;
        __d__.email = a.email;
        __d__.username = a.username;
        //__d__.password = a.id;  //ลงทะเบียน ใช้ password muId
        __d__.fname = a.firstname ? a.firstname : '';
        __d__.lname = a.lastname ? a.lastname : '';
        __d__.year = a.year && a.year.trim() !== "" ? 'พ.ศ. ' + a.year : "";
        __d__.role = a.role.replace(/\r/g, '');
        const { id, ...res } = __d__;
        return res;
      }
      return undefined;
    })
    user = user.filter((a: any) => a !== undefined);
    setData(user as never);
  };

  const onDeleteHandler = (email: string) => {
    var _d_ = data.filter(item => item.email !== email)
    setData(_d_);
  }

  const onCreateHandler = async () => {
    if (success || isUploading) return;
    setUploading(true);

    for (let d of data) {
      try {
        await UserProfilesService.create(d)
          .then(async (res: IProfile) => {
            d.id = res.id
            //await UserProfilesService.updateProfile(res.id.toString(), d)
          });

      } catch (e) {
        // ล้มเหลว //
        setOpenSnack(true);
        break;
      }
    }
    setSuccess(true);
    await setTimeout(() => {
      setSuccess(false);
      setUploading(false);
      props?.onCloseHandler();
    }, 5000);

  }

  //--------------------------------------------------//
  //--------------------------------------------------//
  return (
    <>
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={props.onCloseHandler}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <div style={{ flex: 1 }}>
            <Typography variant="h6" p={1} color="#fff">CREATE USER</Typography>
          </div>
        </Toolbar>
      </AppBar>
      <div style={{ display: "flex", alignItems: "center" }}>
        <TableContainer component={Paper} elevation={3} sx={{ m: 2, p: 2 }}>
          <Box sx={{ textAlign: "center" }}>
            <DialogActions>
              <Button component="label" variant="contained" sx={{ m: 1 }}>IMPORT
                <input
                  hidden
                  type="file"
                  id={"csvFileInput"}
                  accept={".csv"}
                  onChange={onChangeHandler}
                /></Button>
              <Box sx={{ position: 'relative' }}>
                <Button onClick={onCreateHandler} variant="contained" disabled={isUploading || data.length === 0} sx={buttonSx}>CREATE</Button>
                {isUploading && (<CircularProgress
                  size={24}
                  sx={{
                    //color: green[500],
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: '-12px',
                    marginLeft: '-12px',
                  }}
                />
                )}
              </Box>
            </DialogActions>
            <Table size="small" sx={{}} aria-label="customized table">
              <HTable cells={headCells} />
              <TableBody>
                {data.map((row: IProfile) => (
                  <TableRow key={row.email}>
                    <TableCell align="right">{row.muId}</TableCell>
                    <TableCell align="center">{row.username}</TableCell>
                    <TableCell align="center">{row.email}</TableCell>
                    <TableCell align="center">{row.year}</TableCell>
                    <TableCell align="center">{row.role?.toString()}</TableCell>
                    <TableCell align="center">
                      <IconButton color="primary" aria-label="delete" component="label">
                        <DeleteIcon onClick={() => onDeleteHandler(row.email)} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </TableContainer>
      </div>

      <Snackbar
        open={openSnack}
        autoHideDuration={5000}
        onClose={() => setOpenSnack(false)}
      >
        <Alert
          onClose={() => setOpenSnack(false)}
          severity="error"
          sx={{ width: "100%" }}
        >
          ไม่สำเร็จ!
        </Alert>
      </Snackbar>

      <Snackbar
        open={isUploading}
      >
        <Alert
          severity="warning"
          sx={{ width: "100%" }}
        >
          กำลังลงทะเบียน กรุณารอสักครู่
        </Alert>
      </Snackbar>

    </>
  )
}
